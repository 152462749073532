// Axios
window.axios = require('axios')

// Toastr
window.toastr = require('toastr')
toastr.options.closeButton = true

// Cookies Manager
window.Cookies = require('js-cookie')

// Popper
window.Popper = require('@popperjs/core')
// jQuery
window.$ = window.jQuery = require('jquery')

// Bootstrap
require('bootstrap')

window.debounce = require('lodash.debounce')

