const createTemplatePage = {
	init() {
		const addTagInput = document.querySelector('#addTag')
		const tagsInput = document.querySelector('#tagsInput')

		if (tagsInput) {
			tagsInput.addEventListener('input', this.handleTagsChange.bind(createTemplatePage))
			tagsInput.addEventListener('keypress', this.handleTagsInputKeyPress.bind(createTemplatePage))
		}

		if (addTagInput) {
			addTagInput.addEventListener('click', this.handleAddTagClick.bind(createTemplatePage))
		}

		this.removeTagBtnsEventsBidning()
	},

	removeTagBtnsEventsBidning() {
		const removeTagBtns = document.querySelectorAll('.remove-tag')
		if (removeTagBtns && removeTagBtns.length > 0) {
			removeTagBtns.forEach(btn => {
				btn.removeEventListener('click', this.handleDeleteTagClick)
				btn.addEventListener('click', this.handleDeleteTagClick)
			})
		}
	},

	handleDeleteTagClick(e) {
		const tagsHiddenInputNode = document.querySelector('#tagsHiddenInput')
		const listTagNode = e.currentTarget.parentElement
		if (listTagNode) {
			let tagName = listTagNode.querySelector('span').innerText
			if (tagName) {
				listTagNode.remove()
				const selectedTagsArray = tagsHiddenInputNode.value.split(',')
				const newSelectedTagsArray = selectedTagsArray.filter(item => item != tagName)
				const newSelectedTagsString = newSelectedTagsArray.join(',')
				tagsHiddenInputNode.value = newSelectedTagsString
			} else {
				console.log('innerText of span not found');
			}
		} else {
			console.log('tag <li> not found');
		}
	},

	handleTagsInputKeyPress(e) {
		if (e.key === 'Enter') {
			this.handleAddTagClick(e)
		}
	},

	handleTagsChange(e) {
		const inputNode = e.currentTarget
		const value = inputNode.value
		// Split by comma and return new map with no whitespaces at the beggining and the end
		if (value.includes(',')) {
			const tagsArray = value.split(',')
				.map(tag => tag.trim())

			tagsArray.forEach(tag => {
				if (tag) {
					this.pushNewTag(tag)
				}
			})

			// Clear the value
			inputNode.value = ''
		}
	},

	handleAddTagClick(e) {
		e.preventDefault()
		const inputNode = document.querySelector('#tagsInput')
		let tag = inputNode.value || ''
		// Split by comma and return new map with no whitespaces at the beggining and the end
		if (tag) {
			tag = tag.trim()
			this.pushNewTag(tag)

			// Clear the value
			inputNode.value = ''
		}
	},

	pushNewTag(tagName) {
		const tagsHiddenInputNode = document.querySelector('#tagsHiddenInput')
		if (
			tagsHiddenInputNode
			&& tagsHiddenInputNode.value.toLowerCase().includes(tagName.toLowerCase())
		) {
			alert('tag already selected')
			return
		}

		if (tagsHiddenInputNode) {
			if (tagsHiddenInputNode.value) {
				tagsHiddenInput.value += ',' + tagName
			} else {
				tagsHiddenInput.value = tagName
			}
		}

		const selectedTagsListNode = document.querySelector('#selected-tags')
		if (selectedTagsListNode) {
			selectedTagsListNode.insertAdjacentHTML(
				'beforeend',
				`<li>
					<span>${tagName}</span>
					<button class="btn btn-sm remove-tag">&times;</button>
				</li>`
			)

			this.removeTagBtnsEventsBidning()
		}
	}
}

module.exports = { createTemplatePage }
