const { default: editorPage } = require('./pages/editor')

require('./bootstrap')
const { confirmOnDelete } = require('./confirmOnDelete')
confirmOnDelete.init()

const { markUploadedFilesOnInputFileChange } = require('./markUploadedFilesOnInputFileChange')
markUploadedFilesOnInputFileChange.init()

/**
 * Executes on Login and Register Pages
 */
if (
	document.location.pathname.startsWith('/login')
	|| document.location.pathname.startsWith('/password/reset/')
	|| document.location.pathname.startsWith('/register')
	|| document.location.pathname.startsWith('/admin/users')
	|| document.location.pathname === '/user/account'
	|| document.location.pathname.match(/^(\/(.+)\/continue-registration\/?)/) !== null
	|| document.location.pathname.match(/^(\/admin\/users\/(.+)\/edit\/?)/) !== null
) {
	const {
		togglePasswordVisibility,
	} = require('./togglePasswordVisibility')
	togglePasswordVisibility.init()
}

if (document.location.pathname.startsWith('/admin/templates')) {
	require('selectize/dist/js/selectize.min')
	const {
		templatesFilesUploader,
	} = require('./templatesFilesUploader')
	templatesFilesUploader.init()
}

if (document.location.pathname === '/') {
	const { homePage } = require('./pages/home')
	homePage.init()
}

if (
	document.location.pathname === '/admin/templates/create'
	|| document.location.pathname.match(/^(\/admin\/templates\/(.+)\/edit\/?)/) !== null
) {
	const { createTemplatePage } = require('./pages/createTemplatePage')
	createTemplatePage.init()
}

if (
	document.location.pathname.match(/^(\/popups\/(.+)\/editor\/?)/) !== null
	|| document.location.pathname === '/admin/popups'
	|| document.location.pathname === '/user/popups'
) {
	editorPage.init()
}
