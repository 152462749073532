const templatesFilesUploader = {
	init() {
		const isAdvancedUpload = (function () {
			const div = document.createElement('div')
			return (
				('draggable' in div ||
					('ondragstart' in div && 'ondrop' in div)) &&
				'FormData' in window &&
				'FileReader' in window
			)
		})()

		const $form = $('form.drag-and-drop-form')

		if (isAdvancedUpload) {
			this.droppedFiles = false

			$form
				.on(
					'drag dragstart dragend dragover dragenter dragleave drop',
					function (e) {
						e.preventDefault()
						e.stopPropagation()
					}
				)
				.on('dragover dragenter', function () {
					$form.addClass('is-dragover')
				})
				.on('dragleave dragend drop', function () {
					$form.removeClass('is-dragover')
				})
				.on('drop', function (e) {
					this.droppedFiles = e.originalEvent.dataTransfer.files
					$form
						.find('.drag-and-drop-input')
						.prop('files', this.droppedFiles)
						.trigger('change')
				})
				.find('.drag-and-drop-input')
				.on(
					'change',
					this.handleFilesUpload.bind(templatesFilesUploader)
				)
		}
	},

	handleFilesUpload(e) {
		const formNode = e.currentTarget.form
		const urlArray = document.location.pathname.split('/')
		const templateId = parseInt(urlArray[urlArray.length - 2])
		const files = [...$(formNode).find('input[type="file"]')[0].files]

		files.forEach((file) => {
			this.upload(file, templateId, formNode)
		})
	},

	upload(file, templateId, formNode) {
		const formData = new FormData()
		formData.set('file', file)

		if (templateId) {
			this.addFileToQueue(file)

			fetch('/api/templates/' + templateId + '/upload', {
				method: 'POST',
				body: formData,
				headers: {
					Accept: 'application/json',
					ContentType: 'application/json',
					Authorization: 'Bearer ' + Cookies.get('authToken'),
				},
			})
				.then((response) => {
					if (response.ok) {
						return response.json()
					} else {
						return toastr.error(response.statusText)
					}
				})
				.then((response) => {
					if (response.status === 200) {
						toastr.success(response.msg)
						this.addFileToCompleted(file)
					} else {
						this.markAsFailedUpload(file)
						toastr.error(response.msg)
					}

					// Reset the form to clear the files so if we
					// retry the change event listener fires
					formNode.reset()
				})
		}
	},

	addFileToQueue(file) {
		$('.upload-status.upload-queue ul')[0].insertAdjacentHTML(
			'beforeend',
			`
			<li class='progress mb-1' data-file-name='${file.name}'>
				<div
					class='progress-bar progress-bar-animated bg-warning progress-bar-striped'
					role='progressbar'
					style='width: 25%;'
					aria-valuenow='25'
					aria-valuemin='0'
					aria-valuemax='100'
				>
					${file.name}
				</div>
			</li>
			`
		)

		$('.upload-status.upload-queue ul li:last .progress-bar').animate(
			{
				width: '100%',
			},
			100
		)
	},

	addFileToCompleted(file) {
		$('.upload-completed ul')[0].insertAdjacentHTML(
			'beforeend',
			`
			<li class='progress mb-1' data-file-name='${file.name}'>
				<div
					class='progress-bar progress-bar-animated bg-success'
					role='progressbar'
					style='width: 100%;'
					aria-valuenow='100'
					aria-valuemin='0'
					aria-valuemax='100'
				>
					${file.name}
				</div>
			</li>
			`
		)

		$(`.upload-queue ul li[data-file-name="${file.name}"]`).remove()
	},

	markAsFailedUpload(file) {
		$(`.upload-queue li[data-file-name="${file.name}"] .progress-bar`)
			.removeClass('progress-bar-striped')
			.removeClass('bg-warning')
			.addClass('bg-danger')
	},
}

module.exports = { templatesFilesUploader }
