export const fontsList = {
  'Open Sans': {
    name: 'Open Sans',
    googleFontURL:
        'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap',
  },
  Roboto: {
    name: 'Roboto',
    googleFontURL:
        'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap',
  },
  Poppins: {
    name: 'Poppins',
    googleFontURL:
        'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap',
  },
  Raleway: {
    name: 'Raleway',
    googleFontURL:
        'https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap',
  },
};
