const {fontsList} = require('../config/fonts');

const generateAllFontsCSSImports = () => {
  let string = '';
  for (const fontIndex in fontsList) {
    const font = fontsList[fontIndex];
    let importFontString = getFontFromGoogleFonts(font.googleFontURL);
    string += importFontString;
  }

  return string;
};

const getFontFromGoogleFonts = (googleFontURL) => {
  return `
      @import url('${googleFontURL}');
    `;
};

module.exports = {generateAllFontsCSSImports};
