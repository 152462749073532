const changePopupName = (formData, instanceSecretKey, authToken) => {
  return fetch(
      '/api/popups/' + instanceSecretKey + '/name' + '?authToken=' + authToken,
      {
        method: 'POST',
        body: formData,
      },
  ).then((resp) => resp.json()).catch((error) => console.log(error));
};

module.exports = {changePopupName};
