const {generateAllFontsCSSImports} = require("../../editor/helpers/fonts");
const homePage = {
  updatedBtnsPositionsTemplatesIds: [],

  init() {
    this.templatesEventsBinding()

    $('.catalog-search input')
      .toArray()
      .forEach(input => {
        input.addEventListener('change', this.handleSearchTemplate)
      })

    $('.tags a')
      .toArray()
      .forEach(tagLink => tagLink.addEventListener('click', this.handleTagClick))
  },

  templatesEventsBinding() {
    $('.templates .templates-item').toArray()
      .forEach(templateNode => {
        templateNode.removeEventListener('click', this.showPreviewModal.bind(homePage))
        templateNode.addEventListener('click', this.showPreviewModal.bind(homePage))
      })

    $('.templates-preview iframe').toArray()
      .forEach(iframeNode => {
        const templateId = parseInt(iframeNode.getAttribute('data-template-id'))
        window.addEventListener('message', (e) => {
          if (e.data.type === 'BRIDGE_LOADED') {
            const iframeHref = iframeNode.src.split('/')[5]
            const bridgeHref = e.data.href.split('/')[5]

            if (iframeHref === bridgeHref) {
              setTimeout(() => {
                iframeNode.contentWindow.postMessage(
                  {
                    type: 'START_COMMUNICATION',
                    templateId: parseInt(iframeNode.getAttribute('data-template-id')),
                  },
                  '*',
                )

                iframeNode.contentWindow.postMessage(
                  {
                    type: 'LOAD_FONTS',
                    templateId: parseInt(iframeNode.getAttribute('data-template-id')),
                    fontsCSSImport: generateAllFontsCSSImports()
                  },
                  '*',
                )

                window.addEventListener('message', (e) => {
                  if (e.data.templateId === templateId) {
                    if (e.data.type === 'ESC_PRESSED') {
                      this.hideOnEsc(iframeNode)
                    } else if (e.data.type === 'OVERLAY_CLICKED') {
                      this.hideOnClickOnGrayArea(iframeNode)
                    }
                  }
                })
              }, 1000)
            }
          }
        })
      })
  },

  handleTagClick(e) {
    const tagLink = e.currentTarget
    const tagName = tagLink.getAttribute('data-tag-name')
    const $categoryNode = $(tagLink).parents('.tags-category')
    if ($categoryNode.length > 0) {
      if (tagName === 'ALL') {
        $categoryNode.find('a:not(a[data-tag-name="ALL"])').removeClass('active')
        if (!$(tagLink).hasClass('active')) {
          homePage.toggleTag(tagLink)
        }
      } else {
        $categoryNode.find('a[data-tag-name="ALL"]').removeClass('active')
        homePage.toggleTag(tagLink)
      }
    }

    if ($categoryNode.find('a.active').length === 0) {
      $categoryNode.find('a[data-tag-name="ALL"]').addClass('active')
    }

    homePage.handleSearchTemplate(e)
  },

  toggleTag(tagLink) {
    $(tagLink).toggleClass('active')
  },

  getSearchAndTags() {
    const searchValue = document.querySelector('.catalog-search input').value
    let selectedTags = {}

    document.querySelectorAll('.tags a.active')
      .forEach(tag => {
        const tagName = tag.getAttribute('data-tag-name')
        const categoryName = $(tag).parents('.tags-category').attr('data-category-name').toLowerCase()
        if (!selectedTags.hasOwnProperty(categoryName)) {
          selectedTags[categoryName] = []
        }

        if (tagName !== 'ALL') {
          selectedTags[categoryName].push(tagName)
        } else {
          selectedTags[categoryName] = []
        }
      })


    return {
      search: searchValue,
      ...selectedTags,
    }
  },

  /**
   * Transform the array to an actual query params string
   * @param {object} queryParams
   * @return {string}
   */
  parseToQueryParams(queryParams) {
    let query = '?'
    for (const paramName in queryParams) {
      if (query !== '?') {
        query += '&'
      }
      query += `${paramName}=`
      if (queryParams[paramName] instanceof Array) {
        if (queryParams[paramName].length > 0) {
          query += queryParams[paramName].join('|')
        }
      } else {
        query += `${queryParams[paramName]}`
      }
    }

    return query
  },

  handleSearchTemplate(e) {
    e.preventDefault()
    const queryParams = homePage.getSearchAndTags()
    const queryString = homePage.parseToQueryParams(queryParams)
    homePage.searchForTemplates(queryString)
      .then(response => {
        if (response.status === 200) {
          const templates = response.templates
          $('.templates').empty()

          templates.forEach(template => {
            $('.templates')[0].insertAdjacentHTML(
              'beforeend',
              `
							<div class='templates-item' data-target='${template.id}'>
								<div class='templates-top'>
									<span class='dot'></span>
									<span class='dot'></span>
									<span class='dot'></span>
								</div>
								<img class='templates-thumbnail' src='${template.screenshot}' alt='${template.name} Thumbnail'>
								<header class='templates-header'>
									<h3 class='templates-name'>${template.name}</h3>
								</header>
							</div>

							<!-- Modal -->
							<div class='templates-preview modal fade' id='${template.id}' tabindex='-1' aria-hidden='true'>
								<div class='modal-dialog'>
									<div class='modal-content'>
										<div class='modal-header flex-column'>
											<h3 class='text-center w-100 mb-3'>Do you want to use this popup?</h3>
											<div class='d-flex justify-content-center w-100'>
												<a href='/popups/${template.unqid}/editor' class='btn btn-success mr-3'>Yes, Let's Make Changes</a>
												<button type='button' class='btn btn-outline-dark' data-dismiss='modal'>No, Choose Another One</button>
											</div>
										</div>
										<div class='modal-body'>
											<iframe
												class='templates-preview-iframe'
												src='${template.popupFile}'
												frameborder='0'
												marginheight='0'
												marginwidth='0'
												style='overflow:hidden;height:100vh; width:100vw'
												height='100%'
												width='100%'
											></iframe>
										</div>
									</div>
								</div>
							</div>
							`,
            )
          })
          homePage.templatesEventsBinding()
        }
      })
  },

  searchForTemplates(query = '') {
    return fetch('/api/templates/search' + query)
      .then(response => response.json())
  },

  showPreviewModal(e) {
    const templateNode = e.currentTarget
    const templateId = parseInt(templateNode.getAttribute('data-target'))
    $(`#${templateId}.modal`).modal('show')
    // this.updateChooseActionsPosition(templateNode)

    if (homePage.updatedBtnsPositionsTemplatesIds.includes(templateId)) {
      return
    } else {
      homePage.updatedBtnsPositionsTemplatesIds.push(templateId)
    }

    const iframeNode = $(templateNode).siblings('#' + templateId + '.templates-preview').find('iframe')[0] || null
    if (!iframeNode) {
      console.log('Iframe not found.')
      return
    }

    fetch('/api/templates/' + templateId + '/meta')
      .then(response => response.json())
      .then(response => {
        let templatePopupTypeMeta = response.meta.find(
          (item) => item.meta_key === 'popupType',
        )

        if (!templatePopupTypeMeta) {
          templatePopupTypeMeta = 'POPUP'
        } else {
          templatePopupTypeMeta = templatePopupTypeMeta.meta_value
        }

        iframeNode.contentWindow.postMessage(
          {
            type: 'POPUP_TYPE',
            popupType: templatePopupTypeMeta || 'POPUP',
            templateId,
          },
          '*',
        )

        const $chooseBtnsWrapper = $(iframeNode).parents('.modal-content').find('.modal-header')
        setTimeout(() => {
          window.addEventListener('message', e => {
            if (e.data.templateId === templateId) {
              if (e.data.type === 'POPUP_DIMENSIONS') {
                const popupHeight = e.data.height
                const popupTop = e.data.top
                const chooseBtnsTop = parseFloat($chooseBtnsWrapper.position().top)

                if (chooseBtnsTop < (popupTop + popupHeight)) {
                  $chooseBtnsWrapper.css('top', '25px')
                  $chooseBtnsWrapper.css('bottom', 'unset')
                }

                $chooseBtnsWrapper.css('visibility', 'unset')
              }
            }
          })
        }, 500)
      })
  },

  hideOnEsc(iframeNode) {
    const templateId = parseInt($(iframeNode).parents('.templates-preview').attr('id'))
    this.hideModalUsingTempateId(templateId)
  },

  hideOnClickOnGrayArea(iframeNode) {
    const templateId = parseInt($(iframeNode).parents('.templates-preview').attr('id'))
    this.hideModalUsingTempateId(templateId)
  },

  hideModalUsingTempateId(templateId) {
    $('#' + templateId + '.templates-preview').modal('hide')
  },
}

module.exports = {homePage}
