const markUploadedFilesOnInputFileChange = {
	init() {
		document.querySelectorAll('.custom-file .custom-file-input[type="file"]')
			.forEach(node => {
				node.addEventListener('change', this.show)
			})
	},

	show(e) {
		let fileName = 'Choose file'

		if (e.currentTarget.files.length > 1) {
			fileName = e.currentTarget.files.length + " Files Selected";
		} else {
			fileName = $(this).val().split("\\").pop();
		}

		$(e.currentTarget)
			.next('.custom-file-label')
			.html(fileName);
	}
}

module.exports = { markUploadedFilesOnInputFileChange }
