const confirmOnDelete = {
	init() {
		document.querySelectorAll('.need-confirmation')
			.forEach(node => {
				node.addEventListener('click', this.askForConfirmation)
			})
	},

	askForConfirmation(e) {
		if (!confirm('Are you sure you want to delete this record ?')) {
			e.preventDefault();
			return
		}
	}
}

module.exports = { confirmOnDelete }
