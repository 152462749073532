const togglePasswordVisibility = {
	init() {
		$('.toggle-password-visibility')
			.toArray()
			.forEach(node => {
				node.addEventListener('click', this.toggle)
			})
	},

	toggle(e) {
		e.preventDefault()
		const buttonNode = e.currentTarget
		const passwordInputNode = $(buttonNode).siblings('input')[0] || null
		if (passwordInputNode) {
			const isHidden = passwordInputNode.getAttribute('type') === 'password'
			if (isHidden) {
				passwordInputNode.type = 'text'
				$(buttonNode).find('i')
					.removeClass('fa-eye')
					.addClass('fa-eye-slash')
			} else {
				passwordInputNode.type = 'password'
				$(buttonNode).find('i')
					.addClass('fa-eye')
					.removeClass('fa-eye-slash')
			}
		}
	}
}

module.exports = {togglePasswordVisibility}
