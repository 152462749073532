import axios from 'axios';
import {changePopupName} from '../../editor/api/popups';

class EditorPage {
  selectedUsers = [];

  constructor() {
    this.setPopupName = this.setPopupName.bind(this);
    this.updateLinkPermissions = this.updateLinkPermissions.bind(this);
    this.handleAddCollaborator = this.handleAddCollaborator.bind(this);
    this.handleUpdateCollaboratorPermissions =
        this.handleUpdateCollaboratorPermissions.bind(this);
    this.handleSearchForUsers = this.handleSearchForUsers.bind(this);
    this.handleStackCollaborators = this.handleStackCollaborators.bind(this);
    this.deleteUserFromStack = this.deleteUserFromStack.bind(this);
    this.copyPopupLinkToClipboard = this.copyPopupLinkToClipboard.bind(this);
    this.handleGetEmbedCodeClick = this.handleGetEmbedCodeClick.bind(this);
  }

  init() {
    const popupNameInputNode = document.querySelector('#popup-name-input');
    if (popupNameInputNode) {
      popupNameInputNode.removeEventListener('change', this.setPopupName);
      popupNameInputNode.addEventListener('change', this.setPopupName);
    } else {
      console.warn('popup name input not found.');
    }

    window.addEventListener(
        'message',
        (event) => {
          if (event.data.type !== 'PUSH_STATE') {
            return;
          }

          if (event.data.url) {
            window.history.pushState({}, document.title, event.data.url);

            // The iframe sent the popup url
            // which means now it's a popup not a template
            // so we can change the name
            if (popupNameInputNode) {
              popupNameInputNode.disabled = false;
            }
          } else {
            console.warn('url to be pushed not found');
          }
        },
        false,
    );

    window.addEventListener('message', (e) => {
      if (e.data.type === 'FILL_EMBED_CODE') {
        this.fillInEmbedCodeTextarea(e.data.code);
      } else if (e.data.type === 'ENABLE_SHARING_BTN') {
        this.enableNavSharingBtn(e.data.secretKey);
      }
    });

    this.sharingEventListeners();
  }

  sharingEventListeners() {
    const linkPermissionSelectNode = document.querySelectorAll(
        '.people-with-link select',
    );
    if (linkPermissionSelectNode.length > 0) {
      linkPermissionSelectNode.forEach((item) => {
        item.removeEventListener('change', this.updateLinkPermissions);
        item.addEventListener('change', this.updateLinkPermissions);
      });
    } else {
      console.warn('popup link permission not found.');
    }

    const addCollaboratorFormNode = document.querySelectorAll(
        '.send-invite-section form',
    );
    if (addCollaboratorFormNode.length > 0) {
      addCollaboratorFormNode.forEach((item) => {
        item.removeEventListener('submit', this.handleAddCollaborator);
        item.addEventListener('submit', this.handleAddCollaborator);
        const guestEmailInputNode = $(item).find('input.guest-email')[0];
        guestEmailInputNode.removeEventListener(
            'input',
            debounce(this.handleSearchForUsers, 500),
        );
        guestEmailInputNode.addEventListener(
            'input',
            debounce(this.handleSearchForUsers, 500),
        );
      });
    } else {
      console.warn('add collab form not found.');
    }

    const collaboratorsPermissionsSelectNodes = document.querySelectorAll(
        '.collaborators select.collaborator-permissions',
    );
    collaboratorsPermissionsSelectNodes.forEach((item) => {
      item.removeEventListener(
          'change',
          this.handleUpdateCollaboratorPermissions,
      );
      item.addEventListener('change', this.handleUpdateCollaboratorPermissions);
    });

    const copyLinkBtnNode = document.querySelectorAll('.sharing-copy-link');
    if (copyLinkBtnNode.length > 0) {
      copyLinkBtnNode.forEach((item) => {
        item.removeEventListener('click', this.copyPopupLinkToClipboard);
        item.addEventListener('click', this.copyPopupLinkToClipboard);
      });
    }

    const embedCodeBtnNode = document.querySelectorAll('.sharing-embed-code');
    if (embedCodeBtnNode.length > 0) {
      embedCodeBtnNode.forEach((item) => {
        item.removeEventListener('click', this.handleGetEmbedCodeClick);
        item.addEventListener('click', this.handleGetEmbedCodeClick);
      });
    }

    const cancelEmbedCodeBtnNode =
        document.querySelectorAll('.cancel-embed-code');
    if (cancelEmbedCodeBtnNode.length > 0) {
      cancelEmbedCodeBtnNode.forEach((item) => {
        item.removeEventListener('click', this.handleCancelEmbedCodeClick);
        item.addEventListener('click', this.handleCancelEmbedCodeClick);
      });
    }
  }

  enableNavSharingBtn(secretKey) {
    const sharingBtnNode = document.querySelector('#nav-sharing-btn');
    if (sharingBtnNode) {
      sharingBtnNode.disabled = false;
    }

    const modalNode = document.querySelector('#sharingModal');
    if (modalNode === null) {
      return;
    }

    modalNode.setAttribute('data-secret-key', secretKey);

    const copyBtn = modalNode.querySelector('[data-link]');
    if (copyBtn) {
      copyBtn.setAttribute('data-link', secretKey)
    }
  }

  fillInEmbedCodeTextarea(html) {
    const embedCodeTextareaNode = document.querySelectorAll(
        '.embed-code-textarea',
    );
    embedCodeTextareaNode.forEach((item) => {
      if (item) {
        item.innerText = html;
      }
    });
  }

  handleGetEmbedCodeClick(e) {
    const modalNode = $(e.currentTarget).parents('.modal')[0];
    const sharingSection = modalNode.querySelectorAll('.sharingModal .sharing');
    const embedCodeSection = modalNode.querySelectorAll(
        '.sharingModal .embed-code',
    );

    if (sharingSection.length > 0 && embedCodeSection.length > 0) {
      sharingSection.forEach((item) => {
        item.style.display = 'none';
      });

      embedCodeSection.forEach((item) => {
        item.style.display = 'block';
      });
    }
  }

  handleCancelEmbedCodeClick(e) {
    const sharingSection = document.querySelectorAll('.sharingModal .sharing');
    const embedCodeSection = document.querySelectorAll(
        '.sharingModal .embed-code',
    );

    if (sharingSection.length > 0 && embedCodeSection.length > 0) {
      sharingSection.forEach((item) => {
        item.style.display = 'block';
      });

      embedCodeSection.forEach((item) => {
        item.style.display = 'none';
      });
    }
  }

  copyPopupLinkToClipboard(e) {
    const modalNode = $(e.currentTarget).parents('.modal')[0];
    const copyLinkBtnNode = modalNode.querySelector('.sharing-copy-link');

    navigator.clipboard.writeText(
        document.location.origin +
        '/popups/' +
        copyLinkBtnNode.getAttribute('data-link') +
        '/editor',
    );
    e.currentTarget.innerHTML = '<i class=\'fas fa-link mr-2\'></i>Copied !';

    setTimeout(() => {
      copyLinkBtnNode.innerHTML = '<i class=\'fas fa-link mr-2\'></i>Copy link';
    }, 1000);
  }

  setPopupName(e) {
    const formData = new FormData();
    formData.set('name', e.currentTarget.value);
    const secretKey = document.location.pathname.split('/')[2];
    const editorIframeNode = $('.editor iframe')[0];
    const authToken = editorIframeNode.src.split('/')[4].split('?')[1].split('&')[1].split('authToken=')[1]; // get authToken query param from iframe href

    changePopupName(formData, secretKey, authToken).then((response) => {
      if (response.status === 200) {
        const iframeNode = document.querySelector('iframe');
        if (iframeNode) {
          iframeNode.contentWindow.postMessage(
              {
                type: 'SAVE_POPUP_NAME',
                templateId: parseInt(
                    iframeNode.getAttribute('data-template-id'),
                ),
                name: response.name,
              },
              '*',
          );
        }

        e.target.value = response.name;
        toastr.success(response.msg);
      } else {
        toastr.error(response.msg);
      }
    }).catch((error) => console.log(error));
  }

  updateLinkPermissions(e) {
    const modalNode = $(e.currentTarget).parents('.modal')[0];
    let secretKey = modalNode.getAttribute('data-secret-key');
    let formData = new FormData();
    formData.set('can_view', e.currentTarget.value);

    e.preventDefault();
    fetch('/api/popups/' + secretKey + '/link-permissions', {
      method: 'POST',
      body: formData,
    }).then((response) => response.json()).then((response) => {
      console.log(response);
      if (response.status !== 200) {
        toastr.error(response.msg);
      } else {
        toastr.success(response.msg);
      }
    });
  }

  handleAddCollaborator(e) {
    e.preventDefault();
    const modalNode = $(e.currentTarget).parents('.modal')[0];
    let secretKey = modalNode.getAttribute('data-secret-key');

    // make submit btn disabled
    const submitBtn = e.currentTarget.querySelector('button.submit-invitation');
    if (submitBtn) {
      submitBtn.disabled = true;
    }
    const emailInputNode = e.currentTarget.querySelector('input.guest-email');
    if (!emailInputNode) {
      return;
    }

    const email = emailInputNode.value;

    const emailRegExp = new RegExp(
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
    );
    if (email.match(emailRegExp) === null && this.selectedUsers.length === 0) {
      toastr.warn(`Invalid email (${email})`);
      return;
    } else {
      if (!this.selectedUsers.includes(email) && email !== '') {
        this.selectedUsers.push(email);
      }
    }

    const permissions =
        e.currentTarget.querySelector('select.collaborator-permissions').value ||
        '';
    if (!permissions) {
      toastr.warn('Permissions field is empty!');
      return;
    }

    this.selectedUsers = this.selectedUsers.filter((item) => item !== '');

    this.selectedUsers.forEach((selectedEmail, index) => {
      if (selectedEmail) {
        this.addCollaborator(selectedEmail, permissions, secretKey)
          .then((response) => {
            response = response.data
            if (index === this.selectedUsers.length - 1) {
              toastr.success(response.success_message)
              this.reDrawCollaborators(response.payload.collaborators, modalNode)
              this.hideUsersResults(modalNode)
              this.clearSelectedPopupInvitee()
              // Clear Inputs
              submitBtn.disabled = false
              emailInputNode.value = ''

              this.sharingEventListeners()
            }
          })
      }
    })
  }

  handleUpdateCollaboratorPermissions(e) {
    e.preventDefault();
    const modalNode = $(e.currentTarget).parents('.modal')[0];
    let secretKey = modalNode.getAttribute('data-secret-key');
    const permissionsNode = e.currentTarget;
    const permissions = permissionsNode.value;
    const email = e.currentTarget.previousElementSibling.value;

    if (!email) {
      toastr.warning('email not found.');
      return;
    }

    if (permissions === 'none') {
      this.selectedUsers = this.selectedUsers.filter(selectedEmail => selectedEmail !== email);
    }

    permissionsNode.disabled = true;

    if (email) {
      this.addCollaborator(email, permissions, secretKey)
        .then((response) => {
          response = response.data
          toastr.success(response.success_message);
          this.reDrawCollaborators(response.payload.collaborators, modalNode);
          this.sharingEventListeners();
          permissionsNode.disabled = false;
        })
        .catch((error) => {
          const response = error.response.data
          toastr.error(response.error_message || 'Unknown error occured!')
          permissionsNode.disabled = false;
        });
    }
  }

  addCollaborator(email, permissions, secretKey) {
    const formData = {}
    if (permissions === 'can_view') {
      formData.can_view = 1;
      formData.can_edit = 0;
    } else if (permissions === 'can_edit') {
      formData.can_view = 1;
      formData.can_edit = 1;
    } else {
      formData.can_view = 0;
      formData.can_edit = 0;
    }

    return axios.post(
        '/api/popups/' + secretKey + '/collaborators/' + email + '/add',
        formData,
    )
  }

  reDrawCollaborators(collaborators, modalNode) {
    $('.collaborators .sharing-row:not(.popup-owner)').remove();
    const collaboratorsNode = modalNode.querySelector('.collaborators');
    collaborators.forEach((collaborator) => {
      collaboratorsNode.insertAdjacentHTML(
          'beforeend',
          /* html */
          `<div class='sharing-row'>
								<div class='icon' style='background-color: ${this.getDarkColor()}'>
									${collaborator.user.username.substr(0, 2).toUpperCase()}
								</div>
								<div class='text'>
									${collaborator.user.username}
								</div>
								<div class='permissions'>
									<input type='hidden' name='email' class='collaborator-email' value='${
              collaborator.user.email
          }'>
									<select class='collaborator-permissions'>
										<option value='can_view' ${
              collaborator.can_view ? 'selected' : ''
          }>Can View</option>
										<option value='can_edit' ${
              collaborator.can_edit ? 'selected' : ''
          }>Can Edit</option>
										<option value='none' ${
              !collaborator.can_edit && !collaborator.can_view
                  ? 'selected'
                  : ''
          }>Remove</option>
									</select>
								</div>
							</div>`,
      );
    });
  }

  getDarkColor() {
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += Math.floor(Math.random() * 10);
    }
    return color;
  }

  handleSearchForUsers(e) {
    const inputNode = e.target;
    const searchValue = inputNode.value;
    if (!searchValue) {
      document.querySelector('.autocomplete-search-results').style.display =
          'none';
      return;
    }

    this.searchForUsers(searchValue).then((response) => {
      console.log(response);
      if (response.status === 200) {
        const modalNode = $(inputNode).parents('.modal')[0];
        const searchResultContainerNode = modalNode.querySelector(
            '.autocomplete-search-results .autocomplete-search-scroll div:first-of-type',
        );

        if (searchResultContainerNode) {
          // Clear results
          searchResultContainerNode.querySelectorAll('.popup-invitee').forEach((searchOption) => searchOption.remove());

          if (response.users.length > 0) {
            response.users.forEach((user) => {
              if (!this.selectedUsers.includes(user.email)) {
                searchResultContainerNode.insertAdjacentHTML(
                    'afterbegin',
                    /*html*/ `<div class='popup-invitee' data-user-email='${
                        user.email
                    }'>
											<div class='icon' style='background-color: ${this.getDarkColor()}'>${user.username.substr(0, 2).toUpperCase()}</div>
											<div class='member-info'>
												<div class='username'>${user.username}</div>
												<div class='email'>${user.email}</div>
											</div>
										</div>`,
                );
              }
            });
            modalNode.querySelector(
                '.autocomplete-search-results',
            ).style.display = 'block';
            searchResultContainerNode.querySelectorAll('.popup-invitee').forEach((inviteeNode) => {
              inviteeNode.addEventListener(
                  'click',
                  this.handleStackCollaborators,
              );
            });
          }
        }
      }
    });
  }

  clearSelectedPopupInvitee() {
    const selectedInviteesNodes = document.querySelectorAll(
        '.autocomplete-selected .popup-invitee',
    );
    selectedInviteesNodes.forEach((invitee) => invitee.remove());
  }

  hideUsersResults(modalNode) {
    const resultsNode = modalNode.querySelector('.autocomplete-search-results');
    if (resultsNode) {
      resultsNode.style.display = 'none';
    }
  }

  handleStackCollaborators(e) {
    const modalNode = $(e.currentTarget).parents('.modal')[0];
    const guestEmailInputNode = modalNode.querySelector('.guest-email');
    const selectedUserEmail = e.currentTarget.getAttribute('data-user-email');
    this.selectedUsers.push(selectedUserEmail);
    this.hideUsersResults(modalNode);

    const selectedUsersContainerNode = modalNode.querySelector(
        '.autocomplete-selected',
    );
    if (selectedUsersContainerNode) {
      const existingInviteeNodes =
          selectedUsersContainerNode.querySelectorAll('.popup-invitee');
      if (existingInviteeNodes.length > 0) {
        existingInviteeNodes[
        existingInviteeNodes.length - 1
            ].insertAdjacentHTML(
            'afterend',
            this.getAddedUserEmailCardHTML(selectedUserEmail),
        );
      } else {
        selectedUsersContainerNode.insertAdjacentHTML(
            'afterbegin',
            this.getAddedUserEmailCardHTML(selectedUserEmail),
        );
      }

      // Clean email input
      if (guestEmailInputNode) {
        guestEmailInputNode.value = '';
      }

      selectedUsersContainerNode.querySelectorAll('.popup-invitee .delete').forEach((deleteBtnNode) => {
        deleteBtnNode.removeEventListener('click', this.deleteUserFromStack);
        deleteBtnNode.addEventListener('click', this.deleteUserFromStack);
      });
    }
  }

  getAddedUserEmailCardHTML(selectedUserEmail) {
    return /*html*/ `
		<div class='popup-invitee' data-user-email='${selectedUserEmail}'>
			<div class='member-info'>${selectedUserEmail}</div>
			<button class='btn delete p-0 ml-1'>&times;</button>
		</div>
		`;
  }

  deleteUserFromStack(e) {
    const inviteeNode = e.currentTarget.parentElement;
    const userEmail = inviteeNode.getAttribute('data-user-email');
    this.selectedUsers = this.selectedUsers.filter(
        (email) => email !== userEmail,
    );
    inviteeNode.remove();
  }

  searchForUsers(searchValue) {
    return fetch('/api/users/' + encodeURIComponent(searchValue)).then(
        (response) => response.json(),
    );
  }

  copyToClipboard(text) {
    const input = document.body.appendChild(document.createElement('input'));
    input.value = text;
    input.focus();
    input.select();
    document.execCommand('copy');
    input.parentNode.removeChild(input);
  }
}

const editorPage = new EditorPage();
export default editorPage;
